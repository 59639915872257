import {ExperimentNames, SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import React, {useEffect, useState} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {usePageActions, usePageStateSelector} from '../../../hooks/state'
import {getStaticMapUrl as getStaticMapSelector} from '../../../reducers/static-map-url'
import s from './map.scss'
import {MapProps} from './index'

export const Map = ({mobile, coordinates, language, instance}: MapProps) => {
  const {
    allBreakpoints: {isMapVisible},
  } = useVisibilityStyles()
  const {t} = useTranslation()
  const {experiments} = useExperiments()
  const staticMapEnabled = experiments.enabled(ExperimentNames.StaticMapIntegration)
  const staticMapUrl = usePageStateSelector(getStaticMapSelector)
  const showStaticMap = mobile && staticMapEnabled
  const {getStaticMapUrl} = usePageActions()

  const [shouldMapLoad, setShouldMapLoad] = useState(false)

  useEffect(() => {
    if (showStaticMap) {
      getStaticMapUrl(language)
    }
    setShouldMapLoad(true)
  }, [])

  if (!coordinates || !isMapVisible()) {
    return null
  }

  return showStaticMap ? (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        id="event-details-page-map"
        data-hook={DH.MAP}
        src={staticMapUrl}
        className={s.staticMap}
        alt={t('eventLocationMap')}
        height={360}
        width={640}
      />
    </a>
  ) : (
    <div id="event-details-page-map" className={mobile ? s.mobileMap : s.map} data-hook={DH.MAP}>
      {shouldMapLoad && (
        <iframe
          src={`${SERVER_BASE_URL}/html/google-map?locale=${language}&lat=${coordinates.lat}&lng=${coordinates.lng}&instance=${instance}`}
          className={s.iframe}
          allowFullScreen
          title="Map"
          loading="lazy"
        />
      )}
    </div>
  )
}
