import {hasSeatingPlan, isRegistrationClosedVisible} from '@wix/wix-events-commons-statics'
import {getTickets, hasScheduledTickets} from '../../../../selectors/tickets'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {Header as Presentation} from './header'
import {HeaderOwnProps, HeaderRuntimeProps} from './interfaces'

export const mapRuntime = ({state}: DetailsPageAppProps): HeaderRuntimeProps => ({
  componentSettings: state.component.settings,
  withSeatingPlan: hasSeatingPlan(state.event),
  registrationClosed: isRegistrationClosedVisible(state.event),
  hasScheduledTickets: hasScheduledTickets(getTickets(state)),
})

export const Header = connect<HeaderOwnProps, HeaderRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
