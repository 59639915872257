import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ShortDateLocationRuntimeProps, ShortDateLocationOwnProps} from './interfaces'
import {ShortDateLocation as Presentation} from './short-date-location'

const mapRuntime = ({state}: DetailsPageAppProps): ShortDateLocationRuntimeProps => ({
  event: state.event,
})

export const ShortDateLocation = connect<ShortDateLocationOwnProps, ShortDateLocationRuntimeProps>(mapRuntime)(
  Presentation,
)
