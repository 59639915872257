import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import c from '../../../../classnames.scss'
import {getFee} from '../../../../../selectors/tax-and-fee'
import {useCurrencyFormatter} from '../../../../../../../../commons/hooks/currency'
import s from './wix-fee.scss'
import {WixFeeProps} from './index'

export const WixFee = ({ticket, t, event, className, overridePrice}: WixFeeProps) => {
  const abstract = isDonationTicketDefinition(ticket) && !overridePrice
  const {getFormattedMoney} = useCurrencyFormatter()
  const fee = getFee({event, ticket, t, overridePrice, abstract, getFormattedMoney})

  if (!fee) {
    return null
  }

  return (
    <p
      className={classNames(c.evTicketAdditionalInfoColor, s.wixFee, className)}
      data-hook={DH.WIX_FEE}
      aria-label={fee}
    >
      {fee}
    </p>
  )
}
