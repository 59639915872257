import {hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {isCheckoutButtonDisabled} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {Footer as Presentation} from './footer'
import {FooterOwnProps, FooterRuntimeProps} from './interfaces'

export const mapRuntime = ({state, actions: {checkout, openSeatingPlan}}: DetailsPageAppProps): FooterRuntimeProps => ({
  checkoutButtonDisabled: isCheckoutButtonDisabled(state),
  checkout,
  withSeatingPlan: hasSeatingPlan(state.event),
  openSeatingPlan,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
