import React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {QuantityPicker} from '../../../parts/quantity-picker'
import s from './pricing-option-quantity.scss'
import {PricingOptionsQuantityProps} from './interfaces'

export const PricingOptionQuantity = ({
  selectedQuantity,
  max,
  disabled,
  selectTicket,
  ticket,
  pricingOption,
  className,
}: PricingOptionsQuantityProps) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(isMobile ? s.mobileQuantity : s.quantity, className)}>
      <QuantityPicker
        quantity={selectedQuantity}
        min={0}
        max={max}
        disabled={disabled}
        onChange={value => selectTicket({ticketId: ticket.id, count: value, pricingOptionId: pricingOption.id})}
      />
    </div>
  )
}
