import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {TaxOwnProps, TaxRuntimeProps} from './interfaces'
import {Tax as Presentation} from './tax'

export const mapRuntime = ({state: {event}}: DetailsPageAppProps): TaxRuntimeProps => ({
  event,
})

export const Tax = withTranslation()(connect<TaxOwnProps, TaxRuntimeProps>(mapRuntime)(Presentation))
export * from './interfaces'
