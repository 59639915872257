import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {TableRow, TableRowType} from '../../../table-row'
import {TotalPrice} from '../parts/total-price'
import {FormEntry, SeatingPlanModal} from '../../../chunks'
import s from './footer.scss'
import {FooterProps} from './index'

export const Footer = ({checkoutButtonDisabled, checkout, withSeatingPlan, openSeatingPlan}: FooterProps) => {
  const {t} = useTranslation()

  const handleOnClick = () => {
    if (withSeatingPlan) {
      SeatingPlanModal.preload()
      return openSeatingPlan()
    }

    FormEntry.preload()
    return checkout()
  }

  return (
    <div role="rowgroup">
      <TableRow
        config={{
          columns: [
            {
              className: classNames(s.totalPrice, {[s.withSeatingPlan]: withSeatingPlan}),
              content: (
                <>
                  {withSeatingPlan ? null : <TotalPrice />}
                  <RegistrationButtonSkin
                    secondary={true}
                    minWidth="100%"
                    minHeight="48px"
                    disabled={checkoutButtonDisabled}
                    onClick={handleOnClick}
                    dataHook={DH.RESERVE_TICKETS_BUTTON}
                    text={withSeatingPlan ? t('ticketsPicker_selectTickets') : t('checkout')}
                  />
                </>
              ),
            },
          ],
          itemKey: DH.TICKETS_PICKER_FOOTER,
          type: TableRowType.large,
          className: s.totalPriceRow,
        }}
      />
    </div>
  )
}
