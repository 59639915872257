import React, {useState} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getPricingOptions} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {ExpansionButton} from '../../parts/expansion-button'
import {PricingOption} from '../pricing-option'
import {ColumnConfig, TableRow} from '../../../../table-row'
import {PricingOptionsProps} from './interfaces'
import s from './pricing-options.scss'

export const PricingOptions = ({ticket, readonly}: PricingOptionsProps) => {
  const [expanded, setExpanded] = useState(false)
  const {t} = useTranslation()

  const buttonText = expanded
    ? 'ticketsPicker.ticketDescription.showLessPricingOptions'
    : 'ticketsPicker.ticketDescription.showMorePricingOptions'

  const pricingOptions = getPricingOptions(ticket)
  const visibleOptions = expanded ? pricingOptions : [pricingOptions[0]]

  const headerColumns: ColumnConfig[] = [
    {content: t('viewer_ticketsPicker_pricingOptions_ariaTableHeader_optionName')},
    {content: t('viewer_ticketsPicker_pricingOptions_ariaTableHeader_price')},
    {content: t('viewer_ticketsPicker_pricingOptions_ariaTableHeader_quantity')},
    {content: t('viewer_ticketsPicker_pricingOptions_ariaTableHeader_total')},
  ]

  return (
    <>
      <div role="table" aria-describedby={ticket.id} aria-rowcount={pricingOptions.length + 1}>
        <div role="rowgroup" className={s.pricingOptionsHeader}>
          <TableRow
            config={{
              columns: headerColumns,
              itemKey: `${ticket.id}-pricingOptionsHeader`,
              responsiveHideColumns: [1],
            }}
          />
        </div>
        <div role="rowgroup">
          {visibleOptions?.map(pricingOption => (
            <PricingOption key={pricingOption.id} pricingOption={pricingOption} ticket={ticket} readonly={readonly} />
          ))}
        </div>
      </div>
      {pricingOptions.length > 1 ? (
        <ExpansionButton dataHook={DH.MORE_PRICE_OPTIONS_BUTTON} expanded={expanded} onChange={setExpanded}>
          {t(buttonText)}
        </ExpansionButton>
      ) : null}
    </>
  )
}
