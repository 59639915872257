import classNames from 'classnames'
import React from 'react'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import cl from '../classnames.scss'
import s from './subtitle.scss'
import {SubtitleProps} from './index'

export const Subtitle: React.FC<SubtitleProps> = ({dataHook, className, text, mobile, customColor, compact}) => {
  const {experiments} = useExperiments()
  const ticketPickerRedesignEnabled = experiments.enabled(ExperimentNames.TicketPickerRedesign)

  return (
    <h2
      className={classNames(
        cl.evSubtitlesFont,
        customColor || cl.evSubtitlesColor,
        mobile ? s.mobile : s.desktop,
        compact ? s.compact : '',
        ticketPickerRedesignEnabled ? s.upgrade : undefined,
        className,
      )}
      data-hook={dataHook}
    >
      {text}
    </h2>
  )
}
