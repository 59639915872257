import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isRequestPending} from '../../../../selectors/pending-requests'
import {RESERVE_TICKETS} from '../../../../actions/reservation'
import {getTickets} from '../../../../selectors/tickets'
import {Tickets as Presentation} from './tickets'
import {TicketsOwnProps, TicketsRuntimeProps} from './interfaces'

export const mapRuntime = ({state, actions: {selectTicket}}: DetailsPageAppProps): TicketsRuntimeProps => ({
  reserveTicketsPending: isRequestPending(state, RESERVE_TICKETS.REQUEST),
  tickets: getTickets(state),
  selectTicket,
})

export const Tickets = connect<TicketsOwnProps, TicketsRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
