import React from 'react'
import {QuantityPicker} from '../../../parts/quantity-picker'
import s from './quantity.scss'
import {QuantityProps} from './index'

export const Quantity = ({
  selectedQuantity,
  availableTicketsQuantity,
  limit,
  disabled,
  onChange,
  id,
}: QuantityProps) => (
  <div className={s.quantity}>
    <QuantityPicker
      quantity={selectedQuantity}
      min={0}
      max={Math.min(availableTicketsQuantity + selectedQuantity, limit)}
      disabled={disabled}
      onChange={value => onChange({ticketId: id, count: value})}
    />
  </div>
)
