import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import {MembershipOffers} from '../../../event-details/membership-offers'
import {MobileTicket} from '../../../event-details/tickets-picker/ticket'
import {TotalPrice} from '../../../event-details/tickets-picker/parts/total-price'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {usePrevious} from '../../../../../../../commons/hooks/use-previous'
import {useSettings} from '../../../../../hooks/settings'
import {settingsParams} from '../../../../../settingsParams'
import {FormEntry, SeatingPlanModal} from '../../../chunks'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './mobile-tickets-picker.scss'
import {MobileTicketsPickerProps} from './index'

export const MobileTicketsPicker = ({
  componentSettings,
  tickets,
  selectTicket,
  t,
  checkoutButtonDisabled,
  showPaidPlans,
  checkout,
  openCheckoutUnavailable,
  reservationUnavailable,
  withSeatingPlan,
  openSeatingPlan,
}: MobileTicketsPickerProps) => {
  const prevReservationUnavailable = usePrevious(reservationUnavailable)
  const {
    allBreakpoints: {isMembershipsVisible},
  } = useVisibilityStyles()

  const settings = useSettings(componentSettings)
  const ticketsTitleText = settings.get(settingsParams.ticketsTitleText)

  React.useEffect(() => {
    if (prevReservationUnavailable !== reservationUnavailable && reservationUnavailable) {
      openCheckoutUnavailable()
    }
  }, [reservationUnavailable, prevReservationUnavailable, openCheckoutUnavailable])

  const handleOnClick = () => {
    if (withSeatingPlan) {
      SeatingPlanModal.preload()
      return openSeatingPlan()
    }

    FormEntry.preload()
    return checkout()
  }

  return (
    <div data-hook={DH.TICKETS_PICKER} className={classNames(s.mobileCheckoutWrapper, s.mobileButtonFontSize)}>
      {showPaidPlans && isMembershipsVisible() ? (
        <div className={s.membershipOffers}>
          <MembershipOffers />
        </div>
      ) : null}
      <div className={classNames(c.evSubtitlesFont, c.evSubtitlesColor, s.mobileCheckoutHeader)}>
        {ticketsTitleText}
      </div>
      <div className={classNames(c.evTicketDividerColor, s.mobileCheckoutDivider)} />
      {tickets.map(ticket => (
        <MobileTicket onChange={selectTicket} ticket={ticket} key={ticket.id} />
      ))}
      {withSeatingPlan ? null : <TotalPrice />}
      <RegistrationButtonSkin
        secondary
        dataHook={DH.CHECKOUT}
        minWidth="100%"
        disabled={checkoutButtonDisabled}
        onClick={handleOnClick}
        narrowerButton
        text={withSeatingPlan ? t('ticketsPicker_selectTickets') : t('checkout')}
      />
    </div>
  )
}
