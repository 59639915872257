import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import c from '../../../../../classnames.scss'
import {GoesOnSaleProps} from './index'

export const GoesOnSaleLabel = ({startDate, className}: GoesOnSaleProps) => {
  const {t} = useTranslation()

  return (
    <div
      className={classNames(c.evTextColor, className)}
      role="cell"
      aria-label={`${t('ticketsPicker.goesOnSale')}: ${startDate}`}
      data-hook={DH.TICKET_GOES_ON_SALE}
    >
      <p>{t('ticketsPicker.goesOnSale')}</p>
      <p>{startDate}</p>
    </div>
  )
}
