import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {usePrevious} from '../../../../../../commons/hooks/use-previous'
import {Tickets} from './tickets'
import {Footer} from './footer'
import {Header} from './header'
import s from './tickets-picker.scss'
import {TicketsPickerProps} from './index'

export const TicketsPicker = ({
  tickets,
  reservationUnavailable,
  isEditor,
  openCheckoutUnavailable,
}: TicketsPickerProps) => {
  const prevReservationUnavailable = usePrevious(reservationUnavailable)

  React.useEffect(() => {
    if (prevReservationUnavailable !== reservationUnavailable && reservationUnavailable && !isEditor) {
      openCheckoutUnavailable()
    }
  }, [isEditor, reservationUnavailable, prevReservationUnavailable, openCheckoutUnavailable])

  if (!tickets.length) {
    return null
  }

  return (
    <div role="table" className={s.ticketsPicker} data-hook={DH.TICKETS_PICKER}>
      <Header />
      <Tickets />
      <Footer />
    </div>
  )
}
