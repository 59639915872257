import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventTicketLimitPerOrder, isRegistrationClosedVisible} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {getSelectedTicketQuantity, getSelectedTicketsQuantity} from '../../../../../../selectors/selected-tickets'
import {PricingOptionsQuantityOwnProps, PricingOptionsQuantityRuntimeProps} from './interfaces'
import {PricingOptionQuantity as Presentation} from './pricing-option-quantity'

export const mapRuntime = (
  {state, actions: {selectTicket}}: DetailsPageAppProps,
  {ticket, pricingOption}: PricingOptionsQuantityOwnProps,
): PricingOptionsQuantityRuntimeProps => {
  const registrationClosed = isRegistrationClosedVisible(state.event)
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(state.event)
  const availableTicketsQuantity = maxTicketsPerReservation - getSelectedTicketsQuantity(state)
  const selectedPricingOptionQuantity = getSelectedTicketQuantity(state, ticket.id, pricingOption.id)
  const selectedWholeTicketQuantity = getSelectedTicketQuantity(state, ticket.id)
  const ticketLimit = ticket.limitPerCheckout - selectedWholeTicketQuantity + selectedPricingOptionQuantity
  const orderLimit = availableTicketsQuantity + selectedPricingOptionQuantity

  return {
    selectedQuantity: selectedPricingOptionQuantity,
    max: Math.min(orderLimit, ticketLimit),
    disabled: !ticketLimit || registrationClosed,
    selectTicket,
  }
}

export const PricingOptionQuantity = withTranslation()(
  connect<PricingOptionsQuantityOwnProps, PricingOptionsQuantityRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
