import {useEnvironment} from '@wix/yoshi-flow-editor'
import React from 'react'
import classNames from 'classnames'
import s from './expansion-button.scss'
import {ExpansionButtonProps} from './interfaces'

export const ExpansionButton: React.FC<ExpansionButtonProps> = ({
  children,
  className,
  dataHook,
  expanded,
  onChange,
}) => {
  const {isMobile} = useEnvironment()

  return (
    <button
      data-hook={dataHook}
      aria-expanded={expanded}
      className={classNames(s.root, !isMobile ? s.link : s.mobileLink, className)}
      onClick={() => onChange(!expanded)}
    >
      {children}
    </button>
  )
}
