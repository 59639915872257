import React from 'react'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import c from '../../../../classnames.scss'
import s from './updated-indication.scss'
import {UpdatedIndicationProps} from './interfaces'

export const UpdatedIndication = ({formattedUpdatedDate, notFirstItem}: UpdatedIndicationProps) => {
  const {t} = useTranslation()
  return (
    <div className={classNames(s.container, {[s.notFirstItem]: notFirstItem})}>
      <div className={s.pimple} />
      <div className={classNames(s.updatedIndicationText, c.scheduleDurationFont)}>
        {t('updated')} {formattedUpdatedDate}
      </div>
    </div>
  )
}
