import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import s from './rsvp-info-button.scss'
import {RsvpInfoButtonProps} from '.'

export const RsvpInfoButton: React.FC<RsvpInfoButtonProps> = ({
  messages,
  dataHook,
  mobile,
  href,
  onClick,
  navigateToMainPage,
}) => {
  const {isPreview} = useEnvironment()

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (isPreview && !onClick && !href) {
      navigateToMainPage()
    } else {
      onClick()
    }
  }

  return (
    <div className={classNames(s.container, mobile ? s.mobileContainer : '')} data-hook={dataHook}>
      <div>{messages.message}</div>
      <a
        className={s.link}
        data-hook={DH.RSVP_INFO_BUTTON}
        href={href}
        onClick={(isPreview || onClick) && !href ? handleOnClick : null}
      >
        {messages.exploreEventsActionLabel}
      </a>
    </div>
  )
}
