import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {WixFeeOwnProps, WixFeeRuntimeProps} from './interfaces'
import {WixFee as Presentation} from './wix-fee'

export const mapRuntime = ({state: {event}}: DetailsPageAppProps): WixFeeRuntimeProps => ({
  event,
})

export const WixFee = withTranslation()(connect<WixFeeOwnProps, WixFeeRuntimeProps>(mapRuntime)(Presentation))
export * from './interfaces'
