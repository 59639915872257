import classNames from 'classnames'
import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import ChevronUp from 'wix-ui-icons-common/ChevronUp'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {ShowDetailsToggleProps} from './interfaces'
import s from './show-details-toggle.scss'

export const ShowDetailsToggle: React.FC<ShowDetailsToggleProps> = ({expanded, onClick, className}) => {
  const {t} = useTranslation()
  return (
    <button className={classNames(className, s.expand)} onClick={onClick} data-hook={DH.SHOW_DETAILS_TOGGLE}>
      {expanded ? (
        <div data-hook={DH.SHOW_DETAILS_TOGGLE_HIDE}>
          {t('membership_offers_hide')}
          <ChevronUp className={s.suffixIcon} />
        </div>
      ) : (
        <div data-hook={DH.SHOW_DETAILS_TOGGLE_SHOW}>
          {t('membership_offers_show')}
          <ChevronDown className={s.suffixIcon} />
        </div>
      )}
    </button>
  )
}
