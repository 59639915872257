import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Footer} from '../../event-details/schedule/footer'
import {List} from '../../event-details/schedule/list'
import {MobileScheduleProps} from '.'

export const MobileSchedule = ({items, total, navigateToSchedulePage}: MobileScheduleProps) => {
  const {t} = useTranslation()

  return (
    <>
      <List t={t} items={items} mobile detailsContentAlignment />
      <Footer total={total} t={t} mobile navigateToSchedulePage={navigateToSchedulePage} />
    </>
  )
}
