import {withTranslation} from '@wix/yoshi-flow-editor'
import {
  isMemberAbleToFinishRsvp,
  resolveButtonTextTranslation,
  resolveStatusTextTranslation,
} from '../../../selectors/member-rsvp'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {EditRegistrationButton as Presentation} from './edit-registration-button'
import {EditRegistrationButtonRuntimeProps, EditRegistrationButtonOwnProps} from './interfaces'

const mapRuntime = ({state, actions}: DetailsPageAppProps): EditRegistrationButtonRuntimeProps => ({
  messageTranslation: resolveStatusTextTranslation(state),
  buttonTextTranslation: resolveButtonTextTranslation(state),
  changeMemberRsvp: actions.changeMemberRsvp,
  memberAbleToFinishRsvp: isMemberAbleToFinishRsvp(state),
  openConfirmRsvp: actions.openConfirmRsvp,
})

export const EditRegistrationButton = withTranslation()(
  connect<EditRegistrationButtonOwnProps, EditRegistrationButtonRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
