import React, {useEffect} from 'react'
import {ConfirmRsvp, HeadlessError, OrderSummary, SeatingPlanModal, TicketsDownload} from '../chunks'
import {ModalsProps} from './interfaces'
import {NativeModal} from './constants'

export const Modals = ({modalType, plan, blockScroll, unblockScroll}: ModalsProps) => {
  useEffect(() => {
    if (modalType) {
      blockScroll?.()
    } else {
      unblockScroll?.()
    }
    return () => {
      unblockScroll?.()
    }
  }, [modalType])

  switch (modalType) {
    case NativeModal.ORDER_SUMMARY:
      return (
        <LazyModalWrapper>
          <OrderSummary />
        </LazyModalWrapper>
      )
    case NativeModal.TICKETS_DOWNLOAD:
      return (
        <LazyModalWrapper>
          <TicketsDownload />
        </LazyModalWrapper>
      )
    case NativeModal.SEATING_PLAN:
      if (!plan) {
        return null
      }

      return <SeatingPlanModal />
    case NativeModal.CONFIRM_RSVP:
      return (
        <LazyModalWrapper>
          <ConfirmRsvp />
        </LazyModalWrapper>
      )
    case NativeModal.HEADLESS_ERROR:
      return (
        <LazyModalWrapper>
          <HeadlessError />
        </LazyModalWrapper>
      )
    default:
      return null
  }
}

const LazyModalWrapper: React.FC = ({children}) => <React.Suspense fallback={null}>{children}</React.Suspense>
