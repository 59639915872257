import classNames from 'classnames'
import React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {PricingOption} from '@wix/ambassador-wix-events-web/types'
import c from '../../../../../classnames.scss'
import {PricingOptionPrice} from '../pricing-option-price'
import s from './pricing-option-name.scss'

export const PricingOptionName = ({
  pricingOption,
  ticket,
}: {
  pricingOption: PricingOption
  ticket: wix.events.ticketing.TicketDefinition
}) => {
  const {isMobile} = useEnvironment()
  const {name} = pricingOption

  return (
    <>
      <h4 className={classNames(s.name, c.evTicketNameColor, c.evTicketNameFont)} data-hook="name" role="cell">
        {name}
      </h4>
      {!isMobile && (
        <PricingOptionPrice
          className={classNames(s.price, c.evTextFont, c.evTextColor)}
          ticket={ticket}
          pricingOption={pricingOption}
        />
      )}
    </>
  )
}
