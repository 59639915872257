import React from 'react'
import {
  saleEndedAndHasTickets,
  saleScheduledAndHasTickets,
  saleStartedAndHasTickets,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {GoesOnSaleLabel} from '../../../parts/labels/goes-on-sale-label'
import {SaleEndedBadge} from '../../../parts/labels/sale-ended-badge'
import {SoldOutLabel} from '../../../parts/labels/sold-out-label'
import s from './total-column.scss'
import {TotalColumnProps} from './index'

export const TotalColumn = ({total, ticket, withSeatingPlan, registrationClosed, isEventEnded}: TotalColumnProps) => {
  const {t} = useTranslation()
  const {limitPerCheckout, salePeriod} = ticket
  const ariaLabel = withSeatingPlan ? t('ticketsPicker_onSale') : `${t('ticketsPicker.subtotal')}: ${total}`

  return (
    <>
      {saleStartedAndHasTickets(ticket) && (
        <p className={s.total} data-hook="total" role="cell" aria-label={ariaLabel}>
          {withSeatingPlan ? t('ticketsPicker_onSale') : total}
        </p>
      )}
      {saleScheduledAndHasTickets(ticket) &&
        (registrationClosed && isEventEnded ? (
          <SaleEndedBadge />
        ) : (
          <GoesOnSaleLabel startDate={salePeriod.formattedSaleStartDate} className={s.goesOnSaleContainer} />
        ))}
      <SoldOutLabel limit={limitPerCheckout} />
      {saleEndedAndHasTickets(ticket) && <SaleEndedBadge />}
    </>
  )
}
