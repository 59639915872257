import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import alignmentClasses from '../../alignment/alignment.scss'
import {RegistrationButton} from '../../registration-button'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Description} from '../description'
import {SLOT_EVENT_DETAILS_HEADER} from '../../../../../../../commons/constants/slots'
import s from './classic-layout.scss'
import {Image} from './image'
import {ClassicHeaderLayoutProps} from './index'

export const ClassicLayout: React.FC<ClassicHeaderLayoutProps> = ({imageVisible, onClickRegistrationButton}) => {
  const {experiments} = useExperiments()
  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)
  return (
    <div className={classNames(s.wrapper, imageVisible && s.image)}>
      <div className={s.headerBackground} />
      <div className={s.container} data-hook={DH.header}>
        <div
          className={classNames(s.content, s.classicHeaderLayout, alignmentClasses.headerAlignment, {
            [s.contentWithImage]: imageVisible,
          })}
        >
          <ShortDateLocation />
          <Title className={s.eventTitle} />
          {slotsEnabled ? (
            <div className={s.slotHeaderContainer}>
              <SlotsPlaceholder slotId={SLOT_EVENT_DETAILS_HEADER} />
            </div>
          ) : null}
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} />
        </div>
        <Image />
      </div>
    </div>
  )
}
