import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ClosedRegistrationButton} from '../../registration-buttons/closed-registration-button'
import {EditRegistrationButton} from '../../registration-buttons/edit-registration-button'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {TicketsUnavailableButton} from '../../registration-buttons/tickets-unavailable-button'
import {CustomDatesLabel} from '../custom-dates-label'
import {useEventDateInformation} from '../../../../../../commons/hooks/dates'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './registration-button.scss'
import {RegistrationButtonProps} from '.'

export const RegistrationButton = ({
  disableSpacing,
  ticketedEvent,
  registrationClosed,
  memberRsvpExists,
  componentSettings,
  onClick,
  imageVisible,
  isMobile,
  ticketsUnavailable,
  className,
  url,
  isExternalLink,
  withoutRegistration,
  registrationScheduled,
  registrationWithCustomDates,
  eventId,
  t,
  homePageUrl,
  narrowerButton,
}: RegistrationButtonProps) => {
  const {formattedRegistrationStartDate: startDate, formattedRegistrationEndDate: endDate} =
    useEventDateInformation(eventId)

  const {
    allBreakpoints: {isHeaderRegistrationButtonVisible},
  } = useVisibilityStyles()
  const settings = useSettings(componentSettings)
  const rsvpButtonText = settings.getWithFallback(
    ticketedEvent ? settingsParams.ticketedEventButtonText : settingsParams.rsvpEventButtonText,
    settingsParams.rsvpButtonText,
  )

  if (!isHeaderRegistrationButtonVisible()) {
    return null
  }

  const getButton = () => {
    if (registrationScheduled) {
      return (
        <>
          <ClosedRegistrationButton href={homePageUrl} />
          <CustomDatesLabel text={t('registration.opensLabel', {date: startDate})} dataHook={DH.RSVP_OPENS_LABEL} />
        </>
      )
    }

    if (ticketsUnavailable) {
      return <TicketsUnavailableButton href={homePageUrl} />
    }

    if (registrationClosed) {
      return <ClosedRegistrationButton href={homePageUrl} />
    }

    if (memberRsvpExists) {
      return <EditRegistrationButton />
    }

    if (withoutRegistration) {
      return null
    }

    return (
      <>
        <RegistrationButtonSkin
          text={rsvpButtonText}
          onClick={onClick}
          url={url}
          isExternalLink={isExternalLink}
          dataHook={ticketedEvent ? DH.HEADER_GET_TICKETS_BUTTON : DH.RSVP_BUTTON}
          narrowerButton={narrowerButton}
        />
        {registrationWithCustomDates && (
          <CustomDatesLabel text={t('registration.closesLabel', {date: endDate})} dataHook={DH.RSVP_CLOSES_LABEL} />
        )}
      </>
    )
  }

  const button = getButton()

  return button ? (
    <ButtonContainer
      hasImageButtonSpacing={isMobile || imageVisible}
      disableSpacing={disableSpacing}
      className={classNames(s.container, className)}
    >
      {button}
    </ButtonContainer>
  ) : null
}

interface ButtonContainerProps {
  disableSpacing: boolean
  hasImageButtonSpacing: boolean
  className: string
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  hasImageButtonSpacing,
  disableSpacing,
  className,
  children,
}) => {
  const spacingClass = hasImageButtonSpacing ? s.registrationButtonSpacing : s.registrationButtonSpacingNoImage
  return <div className={classNames({[spacingClass]: !disableSpacing}, className)}>{children}</div>
}
