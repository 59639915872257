import React from 'react'
import {Ticket} from '../ticket'
import s from './tickets.scss'
import {TicketsProps} from './index'

export const Tickets = ({tickets, selectTicket, reserveTicketsPending}: TicketsProps) => {
  const onQuantityPickerChange = ({ticketId, count}: {ticketId: string; count: number}) => {
    if (reserveTicketsPending) {
      return
    }
    selectTicket({ticketId, count})
  }

  return (
    <ul className={s.list} role="rowgroup">
      {tickets.map(ticket => (
        <li key={ticket.id}>
          <Ticket ticket={ticket} onChange={onQuantityPickerChange} />
        </li>
      ))}
    </ul>
  )
}
