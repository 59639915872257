import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Badge} from '../../../../../badge'
import {SoldOutLabelProps} from './index'

export const SoldOutLabel = ({limit, className}: SoldOutLabelProps) => {
  const {t} = useTranslation()

  if (limit) {
    return null
  }

  return (
    <Badge className={className} data-hook={DH.TICKET_SOLD_OUT}>
      <p>{t('mobile.ticketsPicker.soldOut')}</p>
    </Badge>
  )
}
