import {hasImage} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isFullWidth, isResponsive} from '../../../../../../../commons/selectors/environment'
import {SideBySideLayout as SideBySideLayoutPresentation} from './side-by-side'

export interface SideBySideLayoutProps extends SideBySideLayoutRuntimeProps, SideBySideLayoutOwnProps {}

export interface SideBySideLayoutOwnProps {
  onClickRegistrationButton: Function
}

export interface SideBySideLayoutRuntimeProps {
  imageVisible: boolean
  fullWidth: boolean
  responsive: boolean
}

const mapRuntime = ({state, host}: DetailsPageAppProps): SideBySideLayoutRuntimeProps => ({
  imageVisible: hasImage(state.event),
  fullWidth: isFullWidth(state, host.dimensions),
  responsive: isResponsive(host),
})

export const SideBySideLayout = connect<SideBySideLayoutOwnProps, SideBySideLayoutRuntimeProps>(mapRuntime)(
  SideBySideLayoutPresentation,
)
