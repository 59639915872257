import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../classnames.scss'
import {ShowDetailsToggle} from '../../show-details-toggle'
import {usePrevious} from '../../../../../../commons/hooks/use-previous'
import {MembershipOffersProps} from './interfaces'
import s from './membership-offers.scss'
import {Plans} from './plans'

export const MembershipOffers = ({
  plans,
  hasPurchasedPlans,
  promptLogin,
  loggedIn,
  openMembershipPicker,
  notPurchasedPlans,
  bestOfferDiscountPercent,
  allPlansPurchased,
  t,
  translatedDetails,
  upgrade,
}: MembershipOffersProps) => {
  const [expanded, setExpanded] = useState(false)
  const previousPlans = usePrevious(plans)

  useEffect(() => {
    if (plans?.length && hasPurchasedPlans) {
      toggleExpand()
    }
  }, [])

  const toggleExpand = () => setExpanded(!expanded)

  useEffect(() => {
    if (!previousPlans?.length && hasPurchasedPlans) {
      toggleExpand()
    }
  }, [previousPlans?.length, hasPurchasedPlans])

  if (!plans.length) {
    return null
  }

  return (
    <div className={classNames(s.wrapper, {[s.upgrade]: upgrade})} data-hook={DH.MEMBERSHIP_OFFERS}>
      <div className={classNames(s.container, c.membershipOffersBorderColor)}>
        <div className={s.heading}>
          <div className={s.content}>
            <div className={s.primary}>{t('membership_offers')}</div>
            {bestOfferDiscountPercent && !allPlansPurchased && (
              <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PROMO}>
                {plans.length > 1
                  ? t('membership_offer_promo_multiple', {percent: bestOfferDiscountPercent})
                  : t('membership_offer_promo', {percent: bestOfferDiscountPercent})}
              </div>
            )}
          </div>
          <ShowDetailsToggle className={s.expand} onClick={toggleExpand} expanded={expanded} />
        </div>
        {expanded && (
          <div data-hook={DH.MEMBERSHIP_OFFERS_DETAILS}>
            <Plans translatedDetails={translatedDetails} plans={plans} />
            {Boolean(notPurchasedPlans.length) && (
              <div className={classNames(s.actions, c.membershipOffersBorderColor)}>
                {!loggedIn && (
                  <div className={s.login}>
                    {t('membership_login_text')}
                    <button
                      className={classNames(s.button)}
                      onClick={() => promptLogin()}
                      data-hook={DH.MEMBERSHIP_OFFERS_LOGIN}
                    >
                      {t('membership_login')}
                    </button>
                  </div>
                )}
                <button
                  className={classNames(s.mainAction)}
                  onClick={() =>
                    openMembershipPicker({
                      titleText: t('membership_thankYou_title'),
                      contentText: t('membership_thankYou_content'),
                      buttonText: t('membership_thankYou_action'),
                    })
                  }
                  data-hook={DH.MEMBERSHIP_OFFERS_BUY}
                >
                  {t('membership_button_buy')}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
