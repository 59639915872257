import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../../../../classnames.scss'
import {ExpansionButton} from '../../../../parts/expansion-button'
import {TicketDescriptionProps} from './interfaces'
import s from './ticket-description.scss'

export const TicketDescription: React.FC<TicketDescriptionProps> = ({
  ticketDefId,
  description,
  expanded,
  expandDescription,
  collapseDescription,
  isMobile,
  t,
}) => {
  const text = `ticketsPicker.ticketDescription.show${expanded ? 'Less' : 'More'}`

  return (
    <div className={classNames(c.evTextFont, c.evTextColor, s.container)}>
      <div aria-live="polite" className={s.container}>
        <p className={classNames({[s.descriptionHidden]: !expanded})} data-hook={DH.DESCRIPTION}>
          {description}
        </p>
      </div>
      <ExpansionButton
        expanded={expanded}
        className={classNames({[s.mobileLink]: isMobile, [s.expandedLink]: !isMobile && expanded})}
        dataHook={DH.MORE_BUTTON}
        onChange={value => {
          value ? expandDescription(ticketDefId) : collapseDescription(ticketDefId)
        }}
      >
        {t(text)}
      </ExpansionButton>
    </div>
  )
}
