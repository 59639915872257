import {withTranslation} from '@wix/yoshi-flow-editor'
import {getTicketDefinitionCurrency} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {calculateSubPrice} from '../../../../../../services/payment'
import {getSelectedTicketQuantity} from '../../../../../../selectors/selected-tickets'
import {PricingOptionsTotalOwnProps, PricingOptionsTotalRuntimeProps} from './interfaces'
import {PricingOptionTotal as Presentation} from './pricing-option-total'

export const mapRuntime = (
  {state}: DetailsPageAppProps,
  {pricingOption, ticket}: PricingOptionsTotalOwnProps,
): PricingOptionsTotalRuntimeProps => {
  const quantity = getSelectedTicketQuantity(state, ticket.id, pricingOption.id)
  return {
    total: {
      amount: calculateSubPrice(Number(pricingOption.price.amount), quantity).toString(),
      currency: getTicketDefinitionCurrency(ticket),
    },
  }
}

export const PricingOptionTotal = withTranslation()(
  connect<PricingOptionsTotalOwnProps, PricingOptionsTotalRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
