import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Minus} from '../../../../../../../../icons/quantity-picker/minus'
import {Plus} from '../../../../../../../../icons/quantity-picker/plus'
import c from '../../../../classnames.scss'
import s from './quantity-picker.scss'
import {QuantityPickerProps} from './index'

export const QuantityPicker = ({quantity, onChange, max, min, disabled, role}: QuantityPickerProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const iconSize = isMobile ? 11 : 9

  const isMinDisabled = disabled || quantity <= min
  const isMaxDisabled = disabled || quantity >= max

  const getDisabledClassName = (isDisabled: boolean) =>
    isDisabled ? c.quantityPickerDisabledSign : c.quantityPickerEnabledSign

  const containerClassNames = classNames(c.quantityPickerBorder, s.quantityPicker, {[s.disabled]: disabled})
  const minusClassNames = classNames(s.button, getDisabledClassName(isMinDisabled))
  const plusClassNames = classNames(s.button, getDisabledClassName(isMaxDisabled))

  const plusHandler = () => !isMaxDisabled && onChange(quantity + 1)
  const minusHandler = () => !isMinDisabled && onChange(quantity - 1)

  return (
    <div className={containerClassNames} data-hook={DH.QUANTITY_PICKER} role={role}>
      <button
        className={minusClassNames}
        data-hook={DH.MINUS_BUTTON}
        aria-label={t('a11y.decreaseQuantity')}
        onClick={minusHandler}
      >
        <Minus size={iconSize} />
      </button>
      <div
        className={s.counter}
        data-hook={DH.QUANTITY_VALUE}
        aria-label={t('a11y.selectedQuantity', {quantity})}
        aria-live="assertive"
      >
        {quantity}
      </div>
      <button
        className={plusClassNames}
        data-hook={DH.PLUS_BUTTON}
        aria-label={t('a11y.increaseQuantity')}
        onClick={plusHandler}
      >
        <Plus size={iconSize} />
      </button>
    </div>
  )
}
