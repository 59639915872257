import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../../../../../commons/selectors/environment'
import {ticketDescriptionExpanded} from '../../../../../../../selectors/tickets-picker'
import {DetailsPageAppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/context'
import {TicketDescriptionOwnProps, TicketDescriptionRuntimeProps} from './interfaces'
import {TicketDescription as Presentation} from './ticket-description'

export const mapRuntime = (
  {state, actions: {expandDescription, collapseDescription}}: DetailsPageAppProps,
  {ticketDefId}: TicketDescriptionOwnProps,
): TicketDescriptionRuntimeProps => ({
  expanded: ticketDescriptionExpanded(state, ticketDefId),
  isMobile: isMobile(state),
  expandDescription,
  collapseDescription,
})

export const TicketDescription = withTranslation()(
  connect<TicketDescriptionOwnProps, TicketDescriptionRuntimeProps>(mapRuntime)(Presentation),
)
